// -- AUTOGENERATED --

// eslint-disable-next-line no-unused-vars
import APIUtils from '@/util/apiutil'

import Axios from 'axios'
import NETVSConfig from '@/../netvs.config'

/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable object-curly-spacing */
/* eslint-disable array-bracket-spacing */
/* eslint-disable object-shorthand */
export default {

  createParamsList () {
    return {'old': [ ], 'new': [ 'name', 'number', 'street', 'postal_code', 'geo_location', 'site_fq_name']}
  },
  create (config, {name_new, number_new, street_new, postal_code_new, geo_location_new, site_fq_name_new}) {
    const params = { 'new': { 'name': name_new, 'number': number_new, 'street': street_new, 'postal_code': postal_code_new, 'geo_location': geo_location_new, 'site_fq_name': site_fq_name_new}, 'old': { }}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/nd/bldg/create`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  deleteParamsList () {
    return {'old': [ 'number'], 'new': [ ]}
  },
  delete (config, {number_old}) {
    const params = { 'new': { }, 'old': { 'number': number_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/nd/bldg/delete`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  list (config, { gpk, number, gpk_list, fetch_limit, number_list, postal_code, fetch_offset, geo_location, site_fq_name, filter_params_dict, sorting_params_list, geo_location_distance }) {
    const params = APIUtils.denullify_dict({ 'gpk': gpk, 'number': number, 'gpk_list': (gpk_list === null) ? null : JSON.stringify(gpk_list), 'fetch_limit': fetch_limit, 'number_list': (number_list === null) ? null : JSON.stringify(number_list), 'postal_code': postal_code, 'fetch_offset': fetch_offset, 'geo_location': geo_location, 'site_fq_name': site_fq_name, 'filter_params_dict': filter_params_dict, 'sorting_params_list': (sorting_params_list === null) ? null : JSON.stringify(sorting_params_list), 'geo_location_distance': geo_location_distance})
    const cnf = {}
    Object.assign(cnf, (config || {}).netdb_axios_config)
    cnf.params = params
    return Axios.get(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/nd/bldg/list`, cnf)
  // eslint-disable-next-line comma-dangle
  },
  updateParamsList () {
    return {'old': [ 'number'], 'new': [ 'name', 'number', 'street', 'postal_code', 'geo_location', 'site_fq_name']}
  },
  update (config, {name_new, number_old, number_new, street_new, postal_code_new, geo_location_new, site_fq_name_new}) {
    const params = { 'new': { 'name': name_new, 'number': number_new, 'street': street_new, 'postal_code': postal_code_new, 'geo_location': geo_location_new, 'site_fq_name': site_fq_name_new}, 'old': { 'number': number_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/nd/bldg/update`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
}
